import React from 'react';
import './App.css';

function App() {
  return (
    <div className="container">
        <div className="logo">

        </div>
        <div className="loader-wrapper">
            <div className="loader">Loading...</div>
        </div>
        <div className="main-bar progress-bar">
            <div className="filled progress-bar">
                <div className="animated progress-bar flow">

                </div>
            </div>
        </div>
        <div className="text">
            САЙТ НАХОДИТСЯ НА РЕКОНСТРУКЦИИ
        </div>
    </div>
  );
}

export default App;
